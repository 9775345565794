<template>
  <div class="main-layout">
    <Loader v-if="$store.state.load" />
    <Sidebar v-model="isOpen" @toggle-sidebar="toggleSidebar" />
    <div class="wrapper" :class="{ fullwidth: !isOpen }">
      <header>
        <Navbar @click="isOpen = !isOpen" />
      </header>
      <section class="content-scroll" ref="scroll" @scroll="showBtnUp">
        <transition name="scroll-toggle">
          <ScrollButton v-if="isShowBtnUp" @throw-page-up="throwPageUp" />
        </transition>
        <main class="app-content">
          <div class="app-page">
            <router-view />
          </div>
        </main>
      </section>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader/Loader";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import ScrollButton from "../components/ScrollButton/ScrollButton";
export default {
  name: "main-layout",
  components: {
    Loader,
    Sidebar,
    Navbar,
    ScrollButton
  },
  data() {
    return {
      isOpen: true,
      isShowBtnUp: false,
      timeToUp: null,
      pageUrl: ""
    };
  },
  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    showBtnUp() {
      if (this.$refs["scroll"].scrollTop > 200) {
        this.isShowBtnUp = true;
      } else {
        this.isShowBtnUp = false;
      }
    },
    throwPageUp() {
      if (this.$refs["scroll"].scrollTop > 0) {
        this.$refs["scroll"].scrollBy(0, -150);
        this.timeToUp = setTimeout(() => {
          this.throwPageUp();
        }, 16);
      } else {
        clearTimeout(this.timeToUp);
      }
    }
  },
  created() {
    this.pageUrl = this.$route.path;
  },
  watch: {
    $route(value) {
      if (value) {
        this.$refs["scroll"].scrollTop = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: 100vh;
  padding-left: 250px;
  color: #3c4b64;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  transition: padding-left 0.3s ease;
  &.fullwidth {
    padding-left: 0;
  }
  @media (max-width: 650px) {
    padding-left: 0;
  }
}

header {
  position: relative;
  z-index: 10;
}

.content-scroll {
  overflow-y: auto;
  height: 100vh;
  background-color: #ddd;
  main.app-content {
    margin: 20px;
    background: #fff;
    .app-page {
      width: 100%;
      min-height: calc(100vh - 95px);
      height: 100%;
      padding: 20px;
      @media (max-width: 768px) {
        padding: 10px;
        min-height: calc(100vh - 75px);
      }
    }
    @media (max-width: 768px) {
      margin: 10px;
    }
  }
}

.scroll-toggle-enter-active,
.scroll-toggle-leave-active {
  transition: opacity 0.5s;
}
.scroll-toggle-enter,
.scroll-toggle-leave-to {
  opacity: 0;
}
</style>
