<template>
  <ul class="sidenav app-sidenav" :class="{ open: value }" ref="sidebar">
    <div class="logo">
      CPA панель
      <i tabindex="1" class="material-icons" @click="toggleSidebar"
        >chevron_right</i
      >
    </div>
    <router-link
      @click.native="toggleSidebar"
      v-for="link in links"
      :key="link.url"
      tag="li"
      active-class="active"
      :to="link.url"
      :exact="link.exact"
    >
      <a href="#" class="waves-effect waves-dark pointer">{{ link.title }}</a>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "sidebar",
  props: ["value"],
  data() {
    return {
      sidebar: null,
      links: [{ title: "Главная страница", url: "/stat", exact: true }]
    };
  },
  methods: {
    toggleSidebar() {
      if (window.innerWidth <= 650) {
        this.$emit("toggle-sidebar");
      }
    }
  },
  mounted() {
    /* global M*/
    this.sidebar = M.Sidenav.init(this.$refs.sidebar, {});
  }
};
</script>

<style lang="scss" scope>
ul.app-sidenav {
  width: 250px;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  bottom: 0 !important;
  height: auto !important;
  &::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #4285f4;
    z-index: 999;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
  &.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  &.sidenav li.active {
    background-color: #4285f4;
    a {
      color: #fff;
    }
  }
  .logo {
    height: 55px;
    display: flex;
    color: #4285f4;
    align-items: center;
    justify-content: center;
    i {
      display: none;
    }
    @media (max-width: 650px) {
      position: relative;

      i {
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 10px;
        font-size: 30px;
        display: inline-block;
        transition: all 0.2s ease;
        &:hover,
        &:focus {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
