<template>
  <div class="loader-wrapper">
    <svg :viewBox="[x, y, w, h].join(' ')">
      <path
        id="inf"
        :d="
          `M${c}${-c}A${r} ${r} 0 1 1 ${c} ${c}L${-c}${-c}A${r} ${r} 0 1 0${-c} ${c}z`
        "
      />
      <use
        xlink:href="#inf"
        :stroke-dasharray="`${d} ${l - d}`"
        :stroke-dashoffset="`${l}px`"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "loader",
  data() {
    return {
      r: 900
    };
  },
  computed: {
    w() {
      return 8 * this.r;
    },
    h() {
      return 0.5 * this.w;
    },
    x() {
      return -0.5 * this.w;
    },
    y() {
      return -0.5 * this.h;
    },
    c() {
      return Math.round(this.r / Math.SQRT2);
    },
    l() {
      return Math.ceil((3 * Math.PI + 4) * this.r);
    },
    d() {
      return ~~(Math.PI * this.r);
    }
  }
};
</script>

<style scoped>
.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 1);
  z-index: 9999;
  top: 0;
  left: 0;
  display: grid;
  place-content: center;
}
svg {
  width: 100%;
  border-radius: 3px;
  background: transparent;
  fill: none;
  stroke: rgba(0, 0, 0, 0.2);
  stroke-linecap: round;
  stroke-width: 5%;
}

use {
  stroke: #4285f4;
  animation: a 1.5s linear infinite;
}

@keyframes a {
  to {
    stroke-dashoffset: 0px;
  }
}
</style>
