<template>
  <nav class="app-navbar white">
    <div class="nav-wrapper">
      <div class="navbar-left">
        <a href="#" @click.prevent="$emit('click')">
          <i class="material-icons theme-color-text">menu</i>
        </a>
      </div>

      <div
        class="dropdown-trigger theme-color-text user-avatar"
        @click="logout"
      >
        <i class="material-icons">exit_to_app</i>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      dropdown: null
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    }
  },
  mounted() {
    /*global M*/
    this.dropdown = M.Dropdown.init(this.$refs.dropdown, {
      coverTrigger: false,
      constrainWidth: false
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
    if (this.dropdown && this.dropdown.destroy) {
      this.dropdown.destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
.app-navbar {
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 2rem;
  box-shadow: none;
  border-bottom: 1px solid #eee;
  transition: width 0.3s ease;
  .nav-wrapper {
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;

    .navbar-left {
      i {
        height: 55px;
        line-height: 55px;
        color: #4285f4;
      }
    }
  }
}

.user-avatar {
  width: 48px;
  height: 48px;
  line-height: 48px;
  color: #4285f4;
  padding: 0;
  text-align: center;
  outline: none;

  i {
    height: 35px;
    line-height: 47px;
    font-size: 29px;
    transition: 0.3s;

    &:focus,
    &:hover {
      color: #799acf;
    }
  }
}
</style>
