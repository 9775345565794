<template>
  <div class="auth-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "auth-layout"
};
</script>

<style lang="scss" scoped>
.auth-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
