<template>
  <button
    class="waves-effect waves-light btn scroll-button"
    @click="$emit('throw-page-up')"
  >
    <i class="material-icons left">expand_less</i>
  </button>
</template>

<script>
export default {
  name: 'scroll-button'
};
</script>

<style lang="scss" scoped>
.scroll-button {
  width: 45px;
  height: 45px;
  position: fixed;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4285f4;
  border: 3px solid white;
  bottom: 25px;
  right: 65px;
  z-index: 500;
  border-radius: 50%;
  &:focus {
    background-color: #4285f4;
  }
  &:hover {
    background-color: #4285f4;
    box-shadow: 0 0 30px 5px #4285f4;
  }
  @media (max-width: 850px) {
    right: 45px;
  }
  i {
    margin: 0;
    font-size: 40px;
  }
}
</style>
