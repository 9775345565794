import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    meta: { layout: "Main" },
    component: () => import("../views/Main.vue")
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "auth" },
    component: () => import("../views/Login.vue")
  },
  {
    path: "/stat",
    name: "main",
    meta: { layout: "main" },
    component: () => import("../views/Main.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   const currentUser = localStorage.getItem("auth");
//   const requireAuth = to.matched.some(route => route.meta.auth);

//   if (requireAuth && !currentUser) {
//     next("/login");
//   } else {
//     next();
//   }
// });

export default router;
