import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    load: false,
    statistic: []
  },
  mutations: {
    toggleLoad(state, loadStatus) {
      state.load = loadStatus;
    },
    setStatistic(state, statistic) {
      state.statistic = statistic;
    }
  },
  actions: {},
  modules: {}
});
