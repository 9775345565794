import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/normalize.scss";
import "./assets/main.scss";
import "materialize-css/dist/js/materialize.min.js";
import "materialize-css/sass/materialize.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
